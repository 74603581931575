import React from "react"
import * as styles from "./global.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Footer from "../components/Homepage/Footer/Footer"
import { Helmet } from "react-helmet"
import GoogleTagManager from "../components/GoogleTagManager/GoogleTagManager"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "data-processing.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function DataProcessing() {
  const { image } = useStaticQuery(getImage)
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competdac - Data Processing</title>
        <meta name="description" content="Data Processing Regulations"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <Image fluid={image.childImageSharp.fluid}></Image>
        <div class={styles.entry_content}>
          <h1
            style={{
              fontSize: "28px",
              textAlign: "center",
              marginBottom: "4%",
            }}
          >
            <strong>
              <span lang="en">DATA PROCESSING POLICY</span>
            </strong>
          </h1>
          <h3 style={{ textAlign: "center" }}>TERMS AND CONDITIONS</h3>
          <h3 style={{ textAlign: "center" }}>
            PURPOSE AND TYPES OF PROCESSED DATA
          </h3>
          <p>&nbsp;</p>
          <p>
            SC COMPETDAC SRL is the company that manages the domain
            www.competdac.ro and its subdomains (hereinafter referred to as
            “SITE”).
          </p>
          <p>
            COMPETDAC SRL is a company organized under the Romanian law, with
            its headquarters in 11 LEONARDO DA VINCI STR., ORADEA, RO26547827,
            J05 /205 /2010, phone no: :{" "}
            <a href="tel:+40735859932">004 0735 859 932</a>.
          </p>
          <p>According to the requirements of Law no. 677/2001</p>
          <p>and</p>
          <p>
            in accordance with the latest applicable regulations starting with
            May 2018
          </p>
          <p>
            <strong>GDPR</strong> – Regulation (EU) 2016/679 of the European
            Parliament and European Council on the protection of individuals
            with regard to the processing of personal data and free movement,
            transfers of such data and the use of appropriate practices,
          </p>
          <p>
            COMPETDAC SRL has the obligation to administer safely and only for
            the specified purposes, the personal data you provide us about you.
          </p>
          <p>
            COMPETDAC SRL{" "}
            <strong>
              does not issue or collect invoices from / to individuals.
            </strong>
          </p>
          <p
            id="tw-target-text"
            class="tw-data-text tw-ta tw-text-medium"
            dir="ltr"
            data-placeholder="Traducere"
          >
            <span lang="en">
              <strong>The purpose of collecting</strong> personal data is only
              one:
            </span>
          </p>
          <ul>
            <li>
              The potential information that we would need to collect, at a
              certain moment, in our contractual relationship with you or with
              your third-party subcontractors/partners/suppliers, will refer
              only to employees of your companies (usually the drivers of the
              trucks/equipments) who, by the nature of the service provided
              under the contract, must access Romanian or foreign military bases
              on our territory or on any other country within the EU or EEA, in
              order to comply with the internal rules of these military bases
              and their mandatory security requirements.
            </li>
          </ul>
          <p
            id="tw-target-text"
            class="tw-data-text tw-ta tw-text-small"
            dir="ltr"
            data-placeholder="Traducere"
          >
            <span lang="en">
              Your employees’/drivers’ data will be sent by e-mail to the
              military base authorities, upon their request, but will not be
              processed or stored by us in any database.
            </span>
          </p>
          <p>
            By the military status and the nature of the contracts, by the
            special Safety and Security safeguards imposed by these military
            bases, in order to allow access for your employees, with various
            types of vehicles/equipments, their personal data are requested to
            be sent to military authorities, prior their access.
          </p>
          <p>
            <strong>The types of information/personal data</strong> we may
            request are, as appropriate:
          </p>
          <ul>
            <li>
              Name and surname of the employee who will need to access that
              military base, its personal numeric code, serial and identity card
              number, mobile phone number, date of birth, gender.
            </li>
          </ul>
          <p>
            By reading this Policy, our company considers that both you and your
            employees have acknowledged these Terms and Conditions, the type of
            information collected and the purpose of the collection, the fact
            that you are guaranteed the rights provided by the law, namely : the
            right to information, the right to access personal data, the right
            to interfier, the right to oppose, the right not to be subject to an
            automatic electronical decision, the right to appeal to justice in
            case of violation of its rights, guaranteed by Laws 677/2001 and
            2016/679 for the protection of subjetcs with the processing of
            personal data and the free movement of such data. At the same time,
            you have the right to oppose the processing of your personal data
            and to request the deletion of all of them or part of.
          </p>
          <p>
            You can fill in a written request, dated and signed and sent to SC
            COMPETDAC SRL, 11 LEONARDO DA VINCI STR. , ORADEA, and like this you
            can exercise the following rights free of charge:
          </p>
          <p>
            – once a year, confirm that your personal data are processed or not;
            <br />
            – oppose your data processing for legitimate reasons relating to
            particular circumstances;
            <br />– request correction, data erasure, except as required by law.
          </p>
          <p>
            SC COMPETDAC SRL does not promote SPAM. Any user/client who
            explicitly provided their email address on www.competdac.ro may
            choose to delete it from our database.
            <br />
            The information you provide will be used only for the purpose you
            have provided it, and to prevent breach of our security, law and
            contractual terms.
          </p>
          <p>
            Also, SC COMPETDAC SRL may disclose your personal data to other
            companies with which it is affiliated (but only on the basis of a
            confidentiality commitment from them to ensure that these data are
            kept secure and that the provision of these personal information is
            made according to the applicable law), as follows: the authorities
            responsible for the safety and security of the military bases, with
            whom our company runs 100% contracts and who requests these data in
            order to allow access of your employees in these bases; other
            companies/subcontractors with whom we can develop joint programs to
            market our products and services; insurance companies.
          </p>
          <p>
            Your personal data may also be provided to authorities or bodies
            such as the Prosecutor’s Office, the Police, the Court and other
            authorized governmental insitutions, on the basis of and within the
            limits of the legal provisions and their express request.
          </p>
        </div>
        <Footer />
      </div>
    </div>
  )
}
